* {
    box-sizing: border-box;
}

body {
    width: 100%;
    height: 100vh;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

main {
    width: 100%;
    max-width: 800px;
    padding: 20px;
    border-radius: 5px;
    background-color: aliceblue;
}

img {
    max-width: 150px;
}

hr {
    border: 0;
    border-bottom: 1px solid black;
    margin: 28px 0;
}

p.jwt-data-name {
    font-size: 14px;
    margin: 0 20px 0 0;
    display: inline-block;
    width: 150px;
    text-align: right;
}

p.jwt-data-value {
    display: inline-block;
    font-size: 14px;
    margin: 0;
    font-weight: 600;
}