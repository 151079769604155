* {
  box-sizing: border-box;
}

body {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: 10px;
  display: flex;
}

main {
  background-color: #f0f8ff;
  border-radius: 5px;
  width: 100%;
  max-width: 800px;
  padding: 20px;
}

img {
  max-width: 150px;
}

hr {
  border: 0;
  border-bottom: 1px solid #000;
  margin: 28px 0;
}

p.jwt-data-name {
  text-align: right;
  width: 150px;
  margin: 0 20px 0 0;
  font-size: 14px;
  display: inline-block;
}

p.jwt-data-value {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
}
/*# sourceMappingURL=index.c419c095.css.map */
